import img from 'assets/applied.jpeg';

const ProjectInfo = [
  {
      "year": 2020,
      "month": 5,
      "managing_department": "과학기술정보통신부",
      "support_agency": "정보통신기획평가원",
      "cooperative_agency": "한국전자통신연구원",
      "project_details": "한국연구재단 부설 정보통신기획평가원 / 정보통신방송 연구개발사업 / 정보통신방송기술개발사업 위탁연구과제(통합이지바로)",
      "research_project_title": "신호기반 IoT 디바이스 DNA 개선을 위한 주변 환경 데이터 학습 모델 연구",
      "leading_research_agency": "한양대학교 에리카 산학 협력단",
      "current_affiliation": "한양대학교 에리카 산학 협력단",
      "research_period": "2020.05.01 ~ 2020.11.30",
      "status": "완료",
      "total_project_duration": "",
      "total_research_funding": "₩51,763,636",
      "cumulative_deposit_amount": "₩36,363,636",
      "category": "정부",
      "research_summary": "proj1",
  },
  {
      "year": 2020,
      "month": 4,
      "managing_department": "과학기술정보통신부",
      "support_agency": "ETRI부설국가보안기술연구소",
      "cooperative_agency": "ETRI부설국가보안기술연구소",
      "project_details": "한국전자통신연구원 / 한국전자통신연구원 부설 국가보안기술연구소 / 국가보안기술연구소 위탁연구사업",
      "research_project_title": "스마트기기 서비스 기반 데이터 획득 기술 연구",
      "leading_research_agency": "한양대학교 에리카 산학 협력단",
      "current_affiliation": "한양대학교 에리카 산학 협력단",
      "research_period": "2020.04.01 ~ 2020.10.31",
      "status": "완료",
      "total_project_duration": "",
      "total_research_funding": "₩55,954,545",
      "cumulative_deposit_amount": "₩45,454,545",
      "category": "정부",
      "research_summary": "proj2",
  },
  {
      "year": 2020,
      "month": 9,
      "managing_department": "과학기술정보통신부",
      "support_agency": "(재)한국연구재단",
      "cooperative_agency": "(재)한국연구재단",
      "project_details": "이공분야기초연구사업 / 생애 첫 연구사업 / 생애 첫 연구",
      "research_project_title": "스마트 스피커의 음성 인터페이스 보안성 강화를 위한 연구",
      "leading_research_agency": "한양대학교 에리카 산학 협력단",
      "current_affiliation": "한양대학교 에리카 산학 협력단",
      "research_period": "2020.09.01~2021.08.31",
      "status": "완료",
      "total_project_duration": "",
      "total_research_funding": "₩48,000,000",
      "cumulative_deposit_amount": "₩30,000,000",
      "category": "정부",
      "research_summary": "proj3",
  },
  {
      "year": 2020,
      "month": 11,
      "managing_department": "지방자치단체",
      "support_agency": "경기도청",
      "cooperative_agency": "한양대학교 에리카산학협력단",
      "project_details": "산학연협력단지 조성사업 / 2차년도 산학연협력단지 조성사업(경기도청) / 기술이전 기반 공동기술개발 지원사업",
      "research_project_title": "환자맞춤형 전주기 재활서비스 제공을 위한 재활로봇기반 소프트웨어플랫폼 개발",
      "leading_research_agency": "한양대학교 에리카 산학 협력단",
      "current_affiliation": "한양대학교 에리카 산학 협력단",
      "research_period": "2020.11.01 ~ 2021.03.31",
      "status": "완료",
      "total_project_duration": "",
      "total_research_funding": "₩59,760,000",
      "cumulative_deposit_amount": "₩45,000,000",
      "category": "정부",
      "research_summary": "proj4",
  },
  {
    "year": 2021,
    "month": 5,
    "managing_department": "교육부",
    "support_agency": "(재)한국연구재단",
    "cooperative_agency": "(재)한국연구재단",
    "project_details": "사회맞춤형 산학협력 선도대학(LINC+) 육성사업 / 사회맞춤형 산학협력 선도대학(LINC+) 육성사업 / 기술개발과제",
    "research_project_title": "착용형 전동보행보조기의 보행분석을 위한 블루투스 기반 모니터링 S/W 개발",
    "leading_research_agency": "한양대학교 에리카 산학 협력단",
    "current_affiliation": "한양대학교 에리카 산학 협력단",
    "research_period": "2021.05.01 ~ 2021.10.31",
    "status": "완료",
    "total_project_duration": "",
    "total_research_funding": "₩41,500,000",
    "cumulative_deposit_amount": "₩40,000,000",
    "category": "정부",
    "research_summary": "proj5",
  },
  {
    "year": 2021,
    "month": 5,
    "managing_department": "과학기술정보통신부",
    "support_agency": "정보통신기획평가원",
    "cooperative_agency": "한국전자통신연구원",
    "project_details": "한국연구재단 부설 정보통신기획평가원 / 정보통신방송 연구개발사업 / 정보보호핵심원천기술개발사업(통합이지바로)",
    "research_project_title": "IoT 디바이스 특성 및 주변 신호 기반 IoT 디바이스 식별방안 연구(본: IoT 디바이스 자율 신뢰보장 기술 및 글로벌 표준기반 IoT 통합보안 오픈 플랫폼 기술개발)",
    "leading_research_agency": "한양대학교 에리카 산학 협력단",
    "current_affiliation": "한양대학교 에리카 산학 협력단",
    "research_period": "2021.05.01 ~ 2021.11.30",
    "status": "완료",
    "total_project_duration": "",
    "total_research_funding": "₩51,763,636",
    "cumulative_deposit_amount": "₩36,363,636",
    "category": "정부",
    "research_summary": "proj6",
    },
    {
    "year": '2022',
    "month": '6',
    "managing_department": "과학기술정보통신부",
    "support_agency": "(재)한국연구재단",
    "cooperative_agency": "(재)한국연구재단",
    "project_details": "이공분야기초연구사업 / 기본연구 / 기본연구",
    "research_project_title": "음성 제어 플랫폼 보안성 강화를 위한 연구",
    "leading_research_agency": "한양대학교 에리카 산학 협력단",
    "current_affiliation": "한양대학교 에리카 산학 협력단",
    "research_period": "2022.06.01 ~ 2024.02.29",
    "status": "1차 완료 / 2차 수행중",
    "total_project_duration": "2022.06.01 ~ 2024.02.29",
    "total_research_funding": "₩58,995,000 / ₩84,540,632",
    "cumulative_deposit_amount": "₩43,245,000 / ₩57,660,000",
    "category": "정부",
    "research_summary": "proj7",
    },
    {
    "year": '2022',
    "month": '11',
    "managing_department": "국방부",
    "support_agency": "국방기술진흥연구소",
    "cooperative_agency": "국방기술진흥연구소",
    "project_details": "핵심기술연구개발사업 / 특화연구실사업 / 특화연구실사업",
    "research_project_title": "우주공간 다중신호 프로토콜 분석 기술 연구",
    "leading_research_agency": "한양대학교 에리카 산학 협력단",
    "current_affiliation": "한양대학교 에리카 산학 협력단",
    "research_period": "2022.11.15 ~ 2023.12.31",
    "status": "완료",
    "total_project_duration": "2022.11.15 ~ 2028.11.14",
    "total_research_funding": "(1차)₩156,300,000 / (2차)₩249,914,020",
    "cumulative_deposit_amount": "(1차)₩87,927,980 / (2차)₩195,587,546",
    "category": "정부",
    "research_summary": "proj8",
    },
    {
    "year": 2023,
    "month": 7,
    "managing_department": "민간기업체",
    "support_agency": "-",
    "cooperative_agency": "(주)헥사휴먼케어",
    "project_details": "산학협력 연구 / 산학협력 연구 / 산학협력연구",
    "research_project_title": "생산관리프로그램 개발",
    "leading_research_agency": "한양대학교 에리카 산학 협력단",
    "current_affiliation": "한양대학교 에리카 산학 협력단",
    "research_period": "2023.07.01 ~ 2023.12.31",
    "status": "완료",
    "total_project_duration": "",
    "total_research_funding": "₩10,000,000",
    "cumulative_deposit_amount": "₩10,000,000",
    "category": "산업체",
    "research_summary": "proj9",
    },
    {
    "year": 2024,
    "month": 1,
    "managing_department": "민간기업체",
    "support_agency": "-",
    "cooperative_agency": "(주)헥사휴먼케어",
    "project_details": "산학협력 연구 / 산학협력 연구 / 산학협력연구",
    "research_project_title": "착용로봇 기반 디지털 서비스 플랫폼(모바일 어플리케이션) 구조 개발",
    "leading_research_agency": "한양대학교 에리카 산학 협력단",
    "current_affiliation": "한양대학교 에리카 산학 협력단",
    "research_period": "2024.01.01 ~ 2024.06.30",
    "status": "수행중",
    "total_project_duration": "2024.01.01 ~ 2024.06.30",
    "total_research_funding": "25000000",
    "cumulative_deposit_amount": "",
    "category": "산업체",
    "research_summary": "proj10",
    },
    {
    "year": 2024,
    "month": 5,
    "managing_department": "과학기술정보통신부",
    "support_agency": "정보통신기획평가원",
    "cooperative_agency": "정보통신기획평가원",
    "project_details": "정보통신방송기술개발사업 / 정보보호핵심원천기술개발",
    "research_project_title": "지능형 서비스 로봇의 사이버 레질리언스 확보를 위한 보안기술 개발",
    "leading_research_agency": "고려대학교 산학협력단",
    "current_affiliation": "고려대학교 산학협력단",
    "research_period": "2024.04.01 ~ 2027.12.31",
    "status": "수행중",
    "total_project_duration": "",
    "total_research_funding": "₩5,501,000,000.00",
    "cumulative_deposit_amount": "",
    "category": "정부",
    "research_summary": "proj11",
    },
    {
    "year": 2024,
    "month": 4,
    "managing_department": "과학기술정보통신부",
    "support_agency": "(재)한국연구재단",
    "cooperative_agency": "(재)한국연구재단",
    "project_details": "개인기초연구(과기정통부)(R&D)",
    "research_project_title": "거대 언어 모델 기반 안드로이드 취약점 탐지 및 자동 수리",
    "leading_research_agency": "한양대학교에리카산학협력단",
    "current_affiliation": "한양대학교에리카산학협력단",
    "research_period": "2024-04-01 ~ 2027-03-31",
    "status": "수행중",
    "total_project_duration": "2024-04-012027-03-31",
    "total_research_funding": "₩737,076,000",
    "cumulative_deposit_amount": "",
    "category": "정부",
    "research_summary": "proj12",
    }
]


export default ProjectInfo;